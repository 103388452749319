<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
      >
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model.number="dataComputed.reservation_type"
              dense
              outlined
              class="text-body-1"
              :label="t('notifications.type')"
              :items="types"
              item-text="text"
              item-value="value"
              :append-icon="option!==1 ? null : icons.mdiMenuDown"
              :readonly="option!==1"
              :rules="[required]"
              @change="onSelectType"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="dataComputed.field_id"
              dense
              outlined
              class="text-body-1"
              :label="t('fields.field')"
              :items="fieldsOptions"
              item-text="name"
              item-value="id"
              :append-icon="option!==1 ? null : icons.mdiMenuDown"
              :readonly="option!==1"
              :rules="[required]"
              @change="onSelectField"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-if="option!==1"
              v-model="dataComputed.reservation_date"
              class="text-body-1"
              :label="t('transactions.date')"
              color="primary"
              outlined
              dense
              persistent-hint
              :rules="[required]"
              :prepend-icon="icons.mdiCalendarBlankOutline"
              :readonly="option!==1"
            ></v-text-field>

            <v-menu
              v-else
              v-model="modalDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dataComputed.reservation_date"
                  :label="t('transactions.date')"
                  :prepend-icon="icons.mdiCalendarBlankOutline"
                  outlined
                  readonly
                  dense
                  clearable
                  class="text-body-1"
                  :rules="[required]"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dataComputed.reservation_date"
                class="text-body-1"
                color="secondary"
                :min="dateNow"
                @input="modalDate = false"
                @change="onSelectDate"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-autocomplete
              v-model="dataComputed.start_time"
              :label="t('transactions.start_time')"
              :items="timeOptions"
              item-text="text"
              item-value="value"
              dense
              outlined
              :prepend-icon="icons.mdiTimerOutline"
              :append-icon="option!==1 ? null : icons.mdiMenuDown"
              :readonly="option!==1"
              :rules="[required]"
            ></v-autocomplete>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-autocomplete
              v-model="dataComputed.reservation_time"
              :label="t('video_details.duration')"
              :items="minuteOptions"
              item-text="text"
              item-value="value"
              dense
              outlined
              :prepend-icon="icons.mdiTimerOutline"
              :append-icon="option!==1 ? null : icons.mdiMenuDown"
              :rules="[required]"
              :readonly="option!==1"
              @change="onSelectDuration"
            ></v-autocomplete>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="dataComputed.price"
              :label="t('transactions.price')"
              outlined
              prefix="$"
              dense
              :readonly="option!==1"
              :rules="[required, regexValidator(dataComputed.price, '^[0-9]{0,9}(\.?[0-9]{0,2})?$', 'Incorrect format')]"
              @input="e => {
                if (!charUnique(e, '.')) {
                  dataComputed.price = null
                }
              }"
              @keypress="onlyNumberDot"
            />
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="computedContacts"
              :label="t('reservations.contact')"
              dense
              outlined
              readonly
              :append-icon="option !==2 ? icons.mdiMagnify : null"
              :rules="[required]"
              @click:append="() => {
                if (option !== 1) return null

                isContactModalOpen = true
              }"
              @click="() => {
                if (option !== 1) return null

                isContactModalOpen = true
              }"
            />
          </v-col>

          <v-col
            cols="12"
            md="3"
            class="d-flex align-center"
            fluid
          >
            <v-switch
              v-model="dataComputed.include_video"
              class="text-body-1 ma-0 pa-0"
              :label="t('reservations.include_video')"
              true-value="Y"
              false-value="N"
              color="secondary"
              hide-details
              :readonly="option!==1"
            />
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              ref="refCopy"
              v-model="dataComputed.share_url"
              :label="t('reservations.reservation_link')"
              dense
              outlined
              readonly
              :append-icon="icons.mdiContentCopy"
              @click:append="copy"
            ></v-text-field>
          </v-col>

          <v-col
            v-if="option===1"
            cols="12"
            md="12"
          >
            <v-subheader>
              <span
                class="title-wrapper mb-2"
              >
                <span>{{ t('reservations.reservation_detail') }}</span>
              </span>
            </v-subheader>
          </v-col>

          <v-col
            v-if="option===1"
            cols="12"
            md="6"
          >
            <v-text-field
              v-if="option===2"
              v-model="dataComputed.start_date"
              class="text-body-1"
              :label="t('transactions.from_date')"
              color="primary"
              outlined
              dense
              persistent-hint
              :prepend-icon="icons.mdiCalendar"
              readonly
            />

            <v-menu
              v-else
              v-model="modalFrom"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dataComputed.start_date"
                  :label="t('transactions.from_date')"
                  :prepend-icon="icons.mdiCalendar"
                  outlined
                  readonly
                  dense
                  clearable
                  class="text-body-1"
                  :rules="[required]"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dataComputed.start_date"
                class="text-body-1"
                color="secondary"
                :min="minDate"
                :max="dataComputed.end_date ? dataComputed.end_date : null"
                @input="modalFrom = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            v-if="option===1"
            cols="12"
            md="6"
          >
            <v-text-field
              v-if="option===2"
              v-model="dataComputed.end_date"
              class="text-body-1"
              :label="t('transactions.from_date')"
              color="primary"
              outlined
              dense
              persistent-hint
              :prepend-icon="icons.mdiCalendar"
              readonly
            />

            <v-menu
              v-else
              v-model="modalTo"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dataComputed.end_date"
                  :label="t('transactions.to_date')"
                  :prepend-icon="icons.mdiCalendar"
                  outlined
                  readonly
                  dense
                  clearable
                  class="text-body-1"
                  :rules="[required]"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dataComputed.end_date"
                class="text-body-1"
                color="secondary"
                :min="dataComputed.start_date"
                @input="modalTo = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            v-if="option===1"
            cols="12"
            md="6"
          >
            <v-select
              v-model="dataComputed.repeat_n_weeks"
              dense
              outlined
              class="text-body-1"
              :label="t('reservations.frequency')"
              :items="recurringFrequencyOptions"
              item-text="text"
              item-value="value"
              :rules="[required]"
            ></v-select>
          </v-col>

          <options-buttom-tab
            :tab-number="tab"
            :is-mode-save-edit="option !== 2"
            :no-save="false"
            :info-required="true"
            :no-cancel="option === 3"
            :no-arrow="true"
            @onCancel="onCancelButton"
            @onNextTab="changeTab"
            @onSave="onSaveAction"
          >
          </options-buttom-tab>
        </v-row>
      </v-form>
    </v-card-text>

    <v-dialog
      v-model="isModalOpen"
      max-width="420px"
    >
      <v-card
        class="pa-3"
        rounded="10"
        style="width: 100%"
      >
        <v-form ref="formDialog">
          <v-app-bar
            flat
            color="rgba(0, 0, 0, 0)"
            dense
            class="px-4"
          >
            <v-toolbar-title class="text-h6 white--text pl-0">
              {{ t('reservations.contact_phone_numbers') }}
            </v-toolbar-title>
          </v-app-bar>

          <v-card-text class="pa-0 mt-5 mb-7 justify-center d-flex flex-column">
            <v-row class="justify-center align-center">
              <v-col
                v-for="(phoneInput, index) in phoneInputs"
                :key="index"
                cols="12"
                md="10"
                class="py-0"
              >
                <div class="d-flex justify-space-between align-items-start">
                  <v-autocomplete
                    v-model="phoneInput.code"
                    dense
                    outlined
                    :items="phoneCodeOptions"
                    item-text="text"
                    item-value="value"
                    style="
                      width: 35%;
                    "
                    :append-icon="option===1 ? icons.mdiMenuDown : null"
                    :readonly="option!==1"
                    hide-details
                    :rules="[required]"
                    @change="() => phoneInput.value = null"
                  >
                    <template v-slot:item="{ item }">
                      <img
                        :src="item.image"
                        :alt="item.value"
                        width="20"
                        height="auto"
                        class="mr-2"
                      >
                      {{ item.text }}
                    </template>
                  </v-autocomplete>

                  <v-text-field
                    v-model="phoneInput.value"
                    :label="t('notify_players.phone')"
                    dense
                    outlined
                    class="pl-2"
                    :class="{ 'mb-2': index > 0 }"
                    :disabled="!phoneInput.code"
                    :readonly="option!==1"
                    :hide-details="index > 0"
                    :rules="(index === 0) ? [required, validatePhones(phoneInput.value, phoneInput)] : [validatePhones(phoneInput.value, phoneInput)]"
                    :append-icon="option === 1 && index > 0 ? icons.mdiClose : null"
                    @click:append="deletePhoneNumber(index)"
                    @keydown.enter.prevent="addPhoneNumber"
                  ></v-text-field>
                </div>
              </v-col>

              <v-col
                v-if="option === 1"
                cols="12"
                md="10"
                class="py-0"
              >
                <p
                  id="addBtnForm"
                  class="text-body-1 ma-0 btn-add"
                >
                  + {{ t('notify_players.add_phone') }}
                </p>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="d-flex flex-column justify-center py-3">
            <!-- <p class="text-xs text-center mb-3">
              {{ t('notify_players.message') }}
            </p> -->
            <div class="d-flex justify-space-between">
              <v-btn
                class="text-xs"
                color="lightGray"
                dark
                width="30%"
                rounded
                outlined
                @click="isModalOpen = false"
              >
                {{ t('tooltip.close') }}
              </v-btn>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable no-useless-return */
import {
  mdiAlertOutline,
  mdiCloudUploadOutline,
  mdiMenuDown,
  mdiClockTimeFourOutline,
  mdiTimerOutline,
  mdiCalendarBlankOutline,
  mdiCalendar,
  mdiClose,
  mdiPencilOutline,
  mdiEyeOutline,
  mdiMagnify,
  mdiContentCopy,
} from '@mdi/js'
import { ref, computed, watch, onBeforeMount, onMounted, getCurrentInstance } from '@vue/composition-api'
import {
  required,
  integerValidator,
  regexValidator,
  charUnique,
  between,
} from '@core/utils/validation'
import { success, error } from '@core/utils/toasted'
import { onlyNumberDot, onlyNumbers } from '@core/utils/functionsHelpers'
import { useUtils } from '@core/libs/i18n'
import { DURATION_OPTIONS, TIME_OPTIONS } from '@core/utils/reservation'
import useCryptoJs from '@core/utils/useCryptoJs'
import useCountry from '@core/utils/useCountry'
import usePhone from '@core/utils/usePhone'
import { copyText } from 'vue3-clipboard'

import Vue from 'vue'
import { VueMaskDirective } from 'v-mask'

import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'

export default {
  components: {
    OptionsButtomTab,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
    facilities: {
      type: Array,
      required: true,
    },
    types: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    playersGroups: {
      type: Array,
      required: true,
    },
    group: {
      type: Number,
      default: 0,
    },
    facility: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { userData } = useCryptoJs()
    const { getCountryCode } = useCountry()
    const { phoneCodeOptions, initPhoneConfig, validatePhones } = usePhone()
    const vm = getCurrentInstance().proxy

    const form = ref(null)
    const refCopy = ref(null)

    const modalDate = ref(false)
    const modalStartTime = ref(false)
    const modalFrom = ref(false)
    const modalTo = ref(false)
    const dateFrom = ref(null)
    const dateTo = ref(null)
    const dateNow = ref(
      new Date(new Date()).toLocaleString('sv-SE', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    )
    const maxDate = ref(
      new Date(+new Date() + 12096e5).toLocaleString('sv-SE', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    )
    const minDate = ref(
      new Date(new Date()).toLocaleString('sv-SE', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    )
    const currentDate = ref(new Date())

    const weekdaysShort = computed(() => [
      { text: t('weekdays_short.mon'), value: 0 },
      { text: t('weekdays_short.tue'), value: 1 },
      { text: t('weekdays_short.wed'), value: 2 },
      { text: t('weekdays_short.thu'), value: 3 },
      { text: t('weekdays_short.fri'), value: 4 },
      { text: t('weekdays_short.sat'), value: 5 },
      { text: t('weekdays_short.sun'), value: 6 },
    ])

    const dataComputed = computed(() => props.dataParams)
    const fieldsOptions = computed(() => props.fields)
    const playersGroupsOptions = computed(() => props.playersGroups)

    const formDialog = ref(null)
    const isModalOpen = ref(false)
    const initCode = ref('')
    const phoneInputs = ref([
      { value: null, code: null, phone: null },
    ])
    const computedPhoneNumbers = computed(() => phoneInputs.value.filter(e => e.phone).map(e => e.phone).join(', '))
    const formContactDialog = ref(null)
    const contacts = ref([])
    const contactSelected = ref(null)
    const isContactModalOpen = ref(false)
    const isNewContact = ref(false)
    const contactData = ref({
      first_name: null,
      last_name: null,
      phone_code: null,
      phone_prefix: null,
      phone_number: null,
      email: null,
      status: 'A',
      categories_ids: [],
    })
    const computedContacts = computed(() => {
      if (contactSelected.value) return `${contactSelected.value.first_name} ${contactSelected.value.last_name}`
      if (props.option !== 1) return dataComputed.value.contact_name

      return null
    })

    const fieldSelected = ref(null)
    const dataAcount = ref({})
    const initialData = {
      name: null,
      note: null,
      playing_time: null,
      status: null,
    }

    const statusOptions = computed(() => [
      { text: t('status.active'), value: 'A' },
      { text: t('status.inactive'), value: 'I' },
    ])
    const timeOptions = ref(TIME_OPTIONS)
    const minuteOptions = ref(DURATION_OPTIONS)
    const recurringFrequencyOptions = computed(() => [
      { text: t('billing_cycle_types.weekly'), value: 1 },
      { text: t('billing_cycle_types.every_two_weeks'), value: 2 },
    ])

    const onSaveAction = () => {
      if (dataComputed.value.weekdays.length === 0) error('Select weekdays!')
      if (form.value.validate() && dataComputed.value.weekdays.length > 0) {
        dataComputed.value.phone_numbers = phoneInputs.value.filter(e => e.phone).map(e => e.phone)
        emit('save', dataComputed.value)
      }
    }

    const onCancelButton = () => {
      form.value.reset()
      dataAcount.value = Object.assign(initialData, {
        name: null,
        note: null,
        playing_time: null,
        status: null,
      })
    }

    const changeTab = tab => {
      if (form.value.validate()) {
        emit('changeTab', tab, true, dataComputed.value)
      }
    }

    const allowedMinutes = m => m % 15 === 0

    const allowedHours = h => {
      if (dateNow.value === dataComputed.value.reservation_date) return h >= currentDate.value.getHours()

      return true
    }

    const onSelectType = type => {
      if (type === 'pick_up') dataComputed.value.group_id = null
      else if (userData.value && userData.value.role !== 'A') dataComputed.value.group_id = userData.value.group_id
      else dataComputed.value.group_id = props.group

      if (type !== 'group') dataComputed.value.circle_ids = []
    }

    const onSelectDuration = duration => {
      const mapPrice = (duration / 60) * fieldSelected.value.booking_hourly_price
      dataComputed.value.price = Math.floor(mapPrice)
    }

    const onSelectField = field => {
      const finded = fieldsOptions.value.find(e => e.id === field)
      if (finded) {
        fieldSelected.value = finded
        dataComputed.value.players_qty = finded.players_qty
        dataComputed.value.price = finded.booking_hourly_price
      }
    }

    const addPhoneNumber = () => {
      if (phoneInputs.value.length < 30) {
        phoneInputs.value.push({ value: null, code: initCode.value, phone: null })
      }
    }

    const deletePhoneNumber = index => {
      phoneInputs.value.splice(index, 1)
    }

    const copy = () => {
      copyText(refCopy.value.value, undefined, e => {
        if (e) {
          error(vm.$t('tooltip.not_copy'))
        } else {
          success(vm.$t('tooltip.copied'))
        }
      })
    }

    watch([isModalOpen], () => {
      if (isModalOpen.value && props.option === 1) {
        setTimeout(() => {
          const btn = document.getElementById('addBtnForm')
          btn.addEventListener('click', addPhoneNumber)
        }, 200)
      }
    })

    onBeforeMount(() => {
      Vue.directive('mask', VueMaskDirective)
    })

    onMounted(async () => {
      if (props.option === 1) {
        const countryCode = getCountryCode()
        if (countryCode) {
          const codeFinded = phoneCodeOptions.value.find(e => e.value === countryCode)
          if (codeFinded) initCode.value = countryCode
          else initCode.value = 'US'
        }
        phoneInputs.value[0].code = initCode.value
      }

      if (props.option !== 1) {
        setTimeout(() => {
          let config = {}
          if (props.dataParams.phone_numbers) {
            phoneInputs.value = props.dataParams.phone_numbers.map(e => {
              config = initPhoneConfig(e)

              return {
                value: config.phone_number,
                code: config.code,
                phone: e,
              }
            })
          }
        }, 1000)
      }
    })

    return {
      // data
      form,
      refCopy,
      dataAcount,
      initialData,
      contactData,
      timeOptions,
      minuteOptions,
      weekdaysShort,
      recurringFrequencyOptions,
      formContactDialog,
      contacts,
      contactSelected,

      modalDate,
      modalStartTime,
      modalFrom,
      modalTo,
      dateFrom,
      dateTo,
      dateNow,
      maxDate,
      minDate,

      formDialog,
      isModalOpen,
      isNewContact,
      isContactModalOpen,
      phoneInputs,
      computedPhoneNumbers,
      computedContacts,
      phoneCodeOptions,

      // computed
      dataComputed,
      statusOptions,
      fieldsOptions,
      playersGroupsOptions,

      // methods
      onCancelButton,
      changeTab,
      onSaveAction,
      allowedMinutes,
      allowedHours,
      onSelectField,
      onSelectDuration,
      onSelectType,
      addPhoneNumber,
      deletePhoneNumber,
      copy,

      // validations
      required,
      integerValidator,
      regexValidator,
      charUnique,
      between,
      onlyNumberDot,
      onlyNumbers,
      validatePhones,

      // i18n
      t,

      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiMenuDown,

        mdiClockTimeFourOutline,
        mdiTimerOutline,
        mdiCalendarBlankOutline,
        mdiCalendar,

        mdiClose,
        mdiPencilOutline,
        mdiEyeOutline,
        mdiMagnify,
        mdiContentCopy,
      },
    }
  },
}
</script>
<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';

@include theme(v-subheader) using ($material) {
  white-space: nowrap;
  position: relative;

  .v-icon {
    color: map-deep-get($material, 'dividers');
  }
  .title-wrapper {
    &:not(.no-style) {
      width: 100%;
      // text-align: center;
      position: absolute;
      left: 0;
      border-bottom: 1px solid map-deep-get($material, 'dividers');
      line-height: 0.1em;
      // margin: 10px 0 20px;
    }
  }

  .title-wrapper span {
    background: map-deep-get($material, 'background');
    padding: 0 10px;

    font-size: 1rem !important;
    font-weight: 400;
    letter-spacing: 0.03125em !important;

    @include ltr() {
      margin-left: 16px;
    }
    @include rtl() {
      margin-right: 16px;
    }
  }
}
</style>
